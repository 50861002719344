import { Route, Routes } from 'react-router-dom'
import Layout from './components/Layout'
import Loader from './components/Loader'
import useAuth from './hooks/useAuth'
import useAxios from './hooks/useAxios'
import useDayjs from './hooks/useDayjs'
import AgencyPage from './pages/AgencyPage'
import DriversPage from './pages/DriversPage'
import LoginPage from './pages/LoginPage'
import MainPage from './pages/MainPage'
import NotFoundPage from './pages/NotFoundPage'
import AgencyRegisterPage from './pages/AgencyRegisterPage'
import InvitationFormPage from './pages/InvitationFormPage'
import InvitationDetailsPage from './pages/InvitationDetailsPage'
import RequireAuth from './components/RequireAuth'
import RequireAgency from './components/RequireAgency'
import SalesPage from './pages/SalesPage'
import SalesDetailPage from './pages/SalesDetailPage'
import VehicleManagementPage from './pages/VehicleManagementPage'

function App() {
  useAxios()
  useDayjs()
  const { isLoading } = useAuth()

  if (isLoading) return <Loader />

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<MainPage />} />
        <Route path="/login" element={<LoginPage />} />

        <Route element={<RequireAuth />}>
          <Route path="/agency/register" element={<AgencyRegisterPage />} />

          <Route element={<RequireAgency />}>
            <Route path="/agency" element={<AgencyPage />} />
            <Route path="/agency/update" element={<AgencyRegisterPage />} />
          </Route>

          {/* 업체 승인이 되여야만 볼 수 있는 페이지 */}
          <Route element={<RequireAgency shouldVerified />}>
            <Route path="/vichel" element={<VehicleManagementPage />} />

            <Route path="/drivers" element={<DriversPage />} />
            <Route path="/drivers/invitations/:id" element={<InvitationDetailsPage />} />
            <Route path="/drivers/invitations/update/:id" element={<InvitationFormPage />} />
            <Route path="/drivers/invitations/register" element={<InvitationFormPage />} />

            <Route path="/sales" element={<SalesPage />} />
            <Route path="/sales/:id" element={<SalesDetailPage />} />
          </Route>
        </Route>
      </Route>

      <Route path="/*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default App
